import * as $ from "jquery";
import {PSO, API} from "./_PSO.js";
import moment from "moment";
// import Services from "./Services";
import Deposits from "./Deposits";
import {Mercure} from "./_mercure";
import {_storage} from "./_storage";
import {_event} from "./_events";

const Hammer = require('hammerjs/hammer');
moment.locale('pl');

$.fn.visible = function() {
    this.css( "visibility", "visible" );
};
$.fn.invisible = function() {
    this.css( "visibility", "hidden" );
};

var Calendar = {};
Calendar.minDate = moment().startOf('week');
Calendar.maxDate = moment().add(3, 'month').endOf('month');
Calendar.startWeek = undefined;
Calendar.endWeek = undefined;
Calendar.lockSwipe = false;
Calendar.current_time = moment();

Calendar.$cards_container = undefined;
Calendar.$slot_template = undefined;
Calendar.$slots_before_noon = undefined;
Calendar.$slots_after_noon = undefined;
Calendar.$month_full = undefined;
Calendar.$book_execute_button = undefined;
Calendar.$confirm_modal = undefined;
Calendar.workshop_id = undefined;
Calendar.workshop = null;
Calendar.slots = [];
Calendar.selected_slot = null;
Calendar.limes = (function () {
    const m = moment().startOf('day');
    return m;
})()
Calendar.weekData = null;
Calendar.loadWeekData = function (from, to, firstOfWeek = true) {
    PSO.preloader.start();
    $(".loaded").hide();
    Calendar.lockSwipe = true;
    //API.abortCall('get-available-terms');
    API.call('GET', 'get-available-terms', {
        workshop_id: Calendar.workshop_id,
        from: from.unix(),
        to: to.unix(),
    }, function (response) {
        let $first;
        Calendar.weekData = response;

        response
            // .filter(item => {

            //     return Calendar.minDate.isBefore(item.from);
            // })
            .reduce((carry, item) => {
            const d = moment(item.from).format('D');
            if (carry.indexOf(d) === -1) {
                carry.push(d);
            }
            return carry;
        }, []).map(day => {
            $(`#day-${day} .disabled`).removeClass('disabled');
            $(`#day-${day}`).unbind('click').on('click', function (e) {
                e.preventDefault();
                Calendar.changeDay($(this).data('date'));
                return false;
            });
            if (!$first) {
                $first = $(`#day-${day}`);
            }
        });
        Calendar.renderCurrent();
        Calendar.lockSwipe = false;
        if (firstOfWeek && $first) {
            $first.trigger('click');
        }
        PSO.preloader.stop();
        $(".loaded").show();
    });
};
Calendar.getWorkshop = function () {
    API.call('GET', 'get-wo-workshop/' + Calendar.workshop_id, null
        , function (response) {
            Calendar.workshop = response;
            $('#calendar-workshop-name').html(Calendar.workshop.name);
        }, function (response) {
            $('.row.white-background-row').html('<div class="alert alert-danger m-2" style="line-height: 1.2">Nie można rezerwować terminu w wybranym serwisie</div>')
        });
};
Calendar.getWorkspace = function (from, to) {
    API.call('GET', 'get-available-terms', {
        workshop_id: Calendar.workshop_id,
        from: moment(Calendar.current_time).startOf('day').unix(),
        to: moment(Calendar.current_time).endOf('day').unix(),
    }, function (response) {
        Calendar.slots = response;
        Calendar.renderSlots(response);
    });
};
Calendar.renderSlots = function (slots) {
    Calendar.$slots_after_noon.html('').removeClass('animate--2 animate--in').addClass('animate--2 animate--before-in');
    Calendar.$slots_before_noon.html('').removeClass('animate--2 animate--in').addClass('animate--2 animate--before-in');

    for (let i in slots) {
        let time = moment(slots[i].from);
        let $slot = Calendar.$slot_template.clone();
        $slot.removeClass('template').attr('id', 'slot-' + i);
        $slot.html(time.format('H:mm'));
        $slot.data('from', slots[i].from);
        $slot.data('to', slots[i].to);
        $slot.click(function () {
            Calendar.changeSlot(i);
        });
        if (parseInt(time.format('H')) < 12) {
            Calendar.$slots_before_noon.append($slot);
        } else {
            Calendar.$slots_after_noon.append($slot);
        }
    }
    if (Calendar.$slots_after_noon.html() === Calendar.$slots_before_noon.html()) {
        $("#calendar-book-execute-button").css('visibility', 'hidden');
    } else {
        $("#calendar-book-execute-button").css('visibility', 'visible');
    }
    if (Calendar.$slots_after_noon.html() === '') Calendar.$slots_after_noon.html('Brak terminów.');
    if (Calendar.$slots_before_noon.html() === '') Calendar.$slots_before_noon.html('Brak terminów.');

    setTimeout(() => {
        Calendar.$slots_after_noon.addClass('animate--2 animate--in')
        Calendar.$slots_before_noon.addClass('animate--2 animate--in')
    }, 300);
};
Calendar.changeSlot = function (slot_nr) {
    if (typeof slot_nr === 'undefined') slot_nr = null;
    $('.calendar-btn').removeClass('selected');
    if (slot_nr === null) {
        Calendar.selected_slot = null;
        Calendar.$book_execute_button.prop('disabled', true);
    } else {
        $('#slot-' + slot_nr).addClass('selected');
        Calendar.selected_slot = slot_nr;
        Calendar.$book_execute_button.prop('disabled', false);
    }
};
Calendar.renderCurrent = function () {
    const D = Calendar.current_time.clone().format('D');
    $('#calendar-cards-container .active').removeClass('active')
    $(`#day-${D}`).addClass('active');
    if (!$(`#day-${D}`).data()) {
        return;
    }
    Calendar.$month_full.html($(`#day-${D}`).data('date').format('MMMM'));
    Calendar.current_time = $(`#day-${D}`).data('date');

    Calendar.renderSlots(Calendar.weekData.filter(item => {
        return moment(item.from).format('D') === D;
    }))
}
Calendar.renderCards = function (direction = false, firstOfWeek = true) {
    let start_of_week = moment(Calendar.current_time).startOf('week');
    let end_of_week = moment(Calendar.current_time).endOf('week');
    //usuwam niedziele
    //end_of_week.set('day', -1);

    Calendar.startWeek = start_of_week;
    Calendar.endWeek = end_of_week;

    Calendar.getWorkspace();
    let days = [];
    let day = start_of_week;
    while (day < end_of_week) {
        days.push(day.toDate());
        day = day.clone().add(1, 'd');
    }


    Calendar.loadWeekData(start_of_week, end_of_week, firstOfWeek);

    Calendar.$month_full.html(moment(Calendar.current_time).format('MMMM'));
    if (direction) {
        Calendar.$cards_container
            .removeClass('animate before-left before-right left right')
            .addClass(`before-${direction}`)
            .html(direction === 'right'
                ? `<div class="to-remove">${Calendar.$cards_container.html().replace(/day-\d+/g, '')}</div><div class="to-replace"></div>`
                : `<div class="to-replace"></div><div class="to-remove">${Calendar.$cards_container.html().replace(/day-\d+/g, '')}</div>`);
    }


    for (let i in days) {
        let $card = $('#calendar-card-template').clone();
        $card.removeClass('template');
        if (moment(days[i]).format('D') === moment(Calendar.current_time).format('D')) {
            $card.addClass('active');
        }
        $card.attr('id', 'day-' + moment(days[i]).format('D'));
        $card.data('date', moment(days[i]));
        $card.find('.calendar-card-day-name').html(moment(days[i]).format('ddd'));
        $card.find('.calendar-card-day-nr').html(moment(days[i]).format('D'));
        $card.find('.calendar-card-dash').addClass('disabled');
        $card.find('.calendar-card-day-name').addClass('disabled');
        $card.find('.calendar-card-day-nr').addClass('disabled');
        if (direction) {
            Calendar.$cards_container.find('.to-replace').append($card);
        } else {
            Calendar.$cards_container.append($card);
        }
    }

    if (direction) {
        Calendar.$cards_container.addClass('animate').addClass(direction);
        Calendar.lockSwipe = true;
        setTimeout(() => {
            Calendar.$cards_container
                .removeClass('animate before-left before-right left right');
            Calendar.$cards_container
                .find('.to-replace')
                .children()
                .each(function () {
                    Calendar.$cards_container.append($(this))
                });
            Calendar.$cards_container
                .find('.to-remove').remove();
            Calendar.$cards_container
                .find('.to-replace').remove();
        }, 600);
    }

};
Calendar.changeMonth = function (how) {

    if (how === true) {
        Calendar.current_time = Calendar.current_time.clone().add(1, 'month').startOf('month');
        if (Calendar.current_time.clone().isAfter(Calendar.maxDate)) {
            Calendar.current_time = Calendar.maxDate;

        }
        Calendar.changeSlot(null);

        Calendar.renderCards('right', false);

    } else if (how === false) {
        Calendar.current_time = Calendar.current_time.clone().subtract(1, 'month').startOf('month');
        if (Calendar.current_time.clone().isBefore(moment())) {
            Calendar.current_time = moment();

        }
        Calendar.changeSlot(null);
        Calendar.renderCards('left', false);
    } else {
        Calendar.current_time = moment(how + '-' + Calendar.current_time.clone().format('M') + '-' + Calendar.current_time.clone().format('Y'), 'D-M-Y');

        Calendar.renderCurrent();
    }

    Calendar.navigationWatch();

}
Calendar.changeDay = function (how) {

    if (how === true) {

        //     Calendar.current_time.clone().add(7, 'days').format(),
        //     Calendar.current_time.clone().add(7, 'days').startOf('week').format())
        Calendar.current_time = Calendar.current_time.clone().add(7, 'days').startOf('week');

        Calendar.changeSlot(null);
        Calendar.renderCards('right');
    } else if (how === false) {
        Calendar.current_time = Calendar.current_time.clone().subtract(7, 'days').startOf('week');

        Calendar.changeSlot(null);
        Calendar.renderCards('left');
    } else {
        Calendar.current_time = how;

        Calendar.renderCurrent();
    }
    Calendar.navigationWatch();
};
Calendar.execute = async function () {
    PSO.preloader.start();


    Deposits().then(deposits => {
        const depositServices = deposits.filter(deposit => 'workshop' in deposit && deposit.workshop && deposit.workshop.code)
            .map(deposit => deposit.workshop.code);

        PSO.getCar(async function () {
            let data = {
                workshop_id: Calendar.workshop_id,
                from: $('.calendar-btn.btn.selected').data('from'),
                to: $('.calendar-btn.btn.selected').data('to'),
                fcmToken: await localStorage.getItem('fcmToken'),
                hasDeposit:
                    window.location.href.match(/code\/([^\/\?]+)/) &&
                    depositServices.indexOf(window.location.href.match(/code\/([^\/\?]+)/)[1]) !== -1
            };
            Mercure.disconnect();
            API.call('POST', 'post-event', data, function () {
                Calendar.$confirm_modal.find('#confirm-workshop-name').html(Calendar.workshop.name);
                Calendar.$confirm_modal.find('#confirm-workshop-street').html(Calendar.workshop.street);
                Calendar.$confirm_modal.find('#confirm-workshop-city').html(Calendar.workshop.city);
                Calendar.$confirm_modal.removeClass('offline');
                if (Calendar.workshop.offline) {
                    Calendar.$confirm_modal.addClass('offline');
                }
                Calendar.$confirm_modal.find('#confirm-date').html(moment($('.calendar-btn.btn.selected').data('from')).format('DD MMMM YYYY [o godz.] H:mm'));
                Calendar.$confirm_modal.fadeIn();
                Calendar.$confirm_modal.find('.confirm-content, .confirm-content-abort').hide();
                Calendar.$confirm_modal.find('#success').show();
                _storage.getItem('reservation');
                Calendar.$confirm_modal.find('.modal-close').unbind('click').click(function () {
                    Calendar.$confirm_modal.fadeOut();
                    window.history.go(-2);
                    Calendar.$confirm_modal.removeClass('offline');
                });
                PSO.preloader.stop();
                _event.emmit(_event.ON_EVENT_CHANGE);
                Mercure.connect();
            }, function (err) {
                if (err.status === 409) {
                    return PSO.gotoDash()
                }
                PSO.preloader.stop();
                Calendar.$confirm_modal.fadeIn();
                Calendar.$confirm_modal.find('.confirm-content, .confirm-content-abort').hide();
                if (err.status === 424) {
                    Calendar.$confirm_modal.find('#error-phone').show();
                } else if (err.status === 406) {
                    Calendar.$confirm_modal.find('#error-event').show();
                } else {
                    Calendar.$confirm_modal.find('#error-unknown').show();
                }
                _event.emmit(_event.ON_EVENT_CHANGE);
                Mercure.connect();
            });
        });
    });
};
Calendar.navigationWatch = function () {
    if (Calendar.current_time.clone().startOf('week') > Calendar.minDate) {
        $('#calendar-week-left').visible()
    } else {
        $('#calendar-week-left').invisible();
    }

    if (Calendar.endWeek < Calendar.maxDate) {
        $('#calendar-week-right').visible();
    } else {
        $('#calendar-week-right').invisible();
    }

    if (Calendar.current_time.clone().startOf('month') > moment().startOf('month')) {
        $('#calendar-left').visible()
    } else {
        $('#calendar-left').invisible()
    }
    if (Calendar.endWeek.endOf('month') < Calendar.maxDate) {
        $('#calendar-right').visible();
    } else {
        $('#calendar-right').invisible();
    }
};
Calendar.init = function () {

    Calendar.$slot_template = $('#slot-template').html('');
    Calendar.$slots_before_noon = $('#slots-before-noon').html('');
    Calendar.$slots_after_noon = $('#slots-after-noon').html('');
    Calendar.$cards_container = $('#calendar-cards-container').html('');
    Calendar.$month_full = $('#calendar-month-full').html('');
    Calendar.$confirm_modal = $('#calendar-confirm-modal');
    Calendar.$book_execute_button = $('#calendar-book-execute-button').unbind('click').click(function () {
        if (!$(this).prop('disabled')) Calendar.execute();
    });
    $('#calendar-confirm-modal').removeClass('offline').removeClass('abort');

    const mc = new Hammer(document.getElementById('calendar-cards-container'));
    mc.off('swipeleft').on('swipeleft', function (ev) {
        !Calendar.lockSwipe && Calendar.endWeek < Calendar.maxDate && Calendar.changeDay(true);
    });
    mc.off('swiperight').on('swiperight', function (ev) {
        !Calendar.lockSwipe && Calendar.current_time.clone().startOf('week') > moment().startOf('week') && Calendar.changeDay(false);
    });
    $('#calendar-week-left').unbind('click').click(function () {
        !Calendar.lockSwipe && Calendar.current_time.clone().startOf('week') > moment().startOf('week') && Calendar.changeDay(false);
    });
    $('#calendar-week-right').unbind('click').click(function () {
        !Calendar.lockSwipe && Calendar.endWeek < Calendar.maxDate && Calendar.changeDay(true);
    });
    $('#calendar-left').unbind('click').click(function () {
        !Calendar.lockSwipe && Calendar.current_time.clone().startOf('month') > moment().startOf('month') && Calendar.changeMonth(false);
    });
    $('#calendar-right').unbind('click').click(function () {
        !Calendar.lockSwipe && Calendar.endWeek.endOf('month') < Calendar.maxDate && Calendar.changeMonth(true);
    });

    Calendar.getWorkshop();

    Calendar.renderCards();

    Calendar.navigationWatch();

    PSO.getCar(() => PSO.getReservation().then(reservation => {
        PSO.gotoDash()
    }));
};



_event.addListener(_event.ON_PAGE_CHANGE, async data => {
    if (data.page !== 'calendar') return;

    $('#slot-template').html('');
    $('#slots-before-noon').html('');
    $('#slots-after-noon').html('');
    $('#calendar-cards-container').html('');
    $('#calendar-month-full').html('');

    Calendar.minDate = moment().startOf('week');
    Calendar.maxDate = moment().add(3, 'month').endOf('month');
    Calendar.startWeek = undefined;
    Calendar.endWeek = undefined;
    Calendar.lockSwipe = false;
    Calendar.current_time = moment();
    Calendar.workshop_id = undefined;
    Calendar.workshop = null;
    Calendar.slots = [];
    Calendar.selected_slot = null;
    Calendar.limes = (function () {
        const m = moment().startOf('day');
        return m;
    })()
    Calendar.weekData = null;

    PSO.preloader.start();
    const code = location.hash.match(/#calendar\/([^\/]+)/)[1];
    Calendar.workshop_id = code;
    const workshops = await _storage.getItem('workshops');
    for (let i in workshops) {
        if (workshops[i].extranetCode == code) {
            Calendar.workshop_id = workshops[i].id;
        }
        if (workshops[i].code == code) {
            Calendar.workshop_id = workshops[i].workshopId;
        }
    }
    try {
        const response = await API.asyncGetCall('get-first-available', {workshop_id: Calendar.workshop_id});
        if (response.minDate) {
            Calendar.minDate = moment(response.minDate).startOf('week');
            Calendar.current_time = moment(response.minDate);
        }
    } catch {}
    PSO.preloader.stop();
    Calendar.init();
});



// if (document.querySelector('[data-calendar]') && window.location.href.match(/calendar/)) {
//     const calendarInit = () => {

//         Calendar.workshop_id = Services.findServiceIdByCode(window.location.hash.match(/#\/([^\/\?]+)/)[1]);
//         PSO.preloader.start();
//         Services.getWorkshops(() => {
//             API.asyncGetCall('get-first-available', {workshop_id: Calendar.workshop_id})
//                 .then((response) => {
//                     if (response.minDate) {
//                         Calendar.minDate = moment(response.minDate).startOf('week');
//                         Calendar.current_time = moment(response.minDate);
//
//                     }
//                     PSO.preloader.stop();
//                     Calendar.init();
//                 })
//                 .catch(() => {
//                     PSO.preloader.stop();
//                     Calendar.init();
//                 })
//         });
//     }
//     (async function (){
//         if (!await PSO.isLoggedIn()) {

//             window.location.href = '/login';
//         } else {
//             calendarInit();
//             window.onhashchange = calendarInit;
//         }
//     })();
// }
// ;
